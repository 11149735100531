import { InformationCircleIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import cartUtils from '../../../../utils/cart';
import CheckoutPaymentButton from './CheckoutPaymentButton';
import CheckoutSummaryLineItem from './CheckoutSummaryLineItem';
import PicsPlusModal from './PicsPlusModal';

const SummaryBox = styled.div`
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin-top: 0;
  text-align: center;
`;

const SummaryHeader = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 24px;
`;

const PicsPlusBanner = styled.div`
  background: #0faaa4ff;
  margin-bottom: -12px;
  margin-left: -24px;
  margin-right: -24px;
  margin-top: 24px;
  color: white;
  padding: 8px 24px;
  text-align: left;
  span {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const InfoButton = styled(InformationCircleIcon)`
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: #327ab7;
  margin-left: 5px;
`;

const ServiceFeeText = styled.p`
  font-weight: 700;
  margin: 0;
`;

const PriceLabel = styled.div`
  display: flex;
  align-items: center;
`;

const CheckoutSummary = ({
  cartTotals,
  discountCodes,
  handleSubmit,
  submittingOrder,
}) => {
  const { offerPicsPlus } = useSelector((state) => state.v3Order);
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const openModal = () => setOpen(true);

  const containsFreeShippingDiscount =
    discountCodes?.some((discountCode) => {
      return discountCode.is_free_shipping;
    }) || false;

  return (
    <SummaryBox>
      <SummaryHeader>Order Summary</SummaryHeader>
      <CheckoutSummaryLineItem label="Subtotal" price={cartTotals['total']} />
      <CheckoutSummaryLineItem
        hideIfZero={true}
        label="Discount"
        price={cartTotals['discount']}
      />
      <CheckoutSummaryLineItem
        hideIfZero={true}
        label="Family Discount"
        price={cartTotals['familyDiscount']}
      />
      {/* Only show shipping if greater than 0 */}
      {cartUtils.cartHasShippingCost(cartTotals) && (
        <CheckoutSummaryLineItem
          label="Shipping"
          showFreeIfDiscount={containsFreeShippingDiscount}
          price={cartTotals['shipping']}
        />
      )}
      <CheckoutSummaryLineItem label="Tax" price={cartTotals['tax']} />
      {offerPicsPlus && (
        <CheckoutSummaryLineItem
          label={
            <PriceLabel>
              Service Fee <InfoButton onClick={openModal} />
            </PriceLabel>
          }
          price={0}
          showZeroIfZero={true}
        />
      )}
      <CheckoutSummaryLineItem
        label="Grand Total"
        price={cartTotals['grandTotal']}
        showZeroIfZero={true}
      />
      <CheckoutPaymentButton
        grandTotal={cartTotals['grandTotal']}
        handleSubmit={() => handleSubmit(false)}
        submittingOrder={submittingOrder}
      />
      {offerPicsPlus && (
        <PicsPlusBanner>
          <ServiceFeeText>
            No Service Fee ever with <span onClick={openModal}>Pics+</span>
          </ServiceFeeText>
          <div>
            Plus <span onClick={openModal}>much more</span>
          </div>
        </PicsPlusBanner>
      )}
      <PicsPlusModal
        open={open}
        onClose={closeModal}
        onSubmit={() => handleSubmit(true)}
      />
    </SummaryBox>
  );
};

export default CheckoutSummary;
