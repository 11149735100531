import styled from 'styled-components';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

export const PicsPlusContainer = styled.div`
  background: white;
  padding: 24px;
`;

export const PicsPlusHeader = styled.h1`
  font-size: 40px;
  font-weight: bold;
  ${'' /* line-height: 72px; */}
  font-family: 'Inter', sans-serif;
  @media screen and (max-width: 500px) {
    font-size: 30px;
    line-height: 38px;
  }
`;

export const PicsPlusHeader2 = styled.h2`
  font-size: 32px;
  font-weight: 700;
  ${'' /* line-height: 48px; */}
  font-family: 'Inter', sans-serif;
  @media screen and (max-width: 500px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

export const CheckMarkBullet = styled(CheckCircleIcon)`
  color: #327ab7;
  min-width: 30px;
  min-height: 30px;
  width: 30px;
  height: 30px;
  margin-top: 8px;
  @media screen and (max-width: 500px) {
    margin-top: 0;
    min-width: 20px;
    min-height: 20px;
    width: 20px;
    height: 20px;
  }
`;

export const PicsPlusListItem = styled.li`
  display: flex;
  gap: 12px;
  margin-bottom: 6px;
`;

export const PicsPlusListItemHeader = styled.p`
  font-size: 24px;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  margin-bottom: 0;
  @media screen and (max-width: 500px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

export const PicsPlusText = styled.p`
  font-size: 14px;
`;

export const PicsPlusAnnualSubBox = styled.div`
  border-radius: 6px;
  border: 10px solid #327ab7;
  text-align: center;
  font-family: 'Inter', sans-serif;
  padding: 14px;
`;

export const PicsPlusAnnualSubBoxHeader = styled.p`
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
`;

export const PicsPlusAnnualSubBoxDate = styled.p`
  color: #327ab7;
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
`;
export const PicsPlusAnnualSubBoxText = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
`;

// export const OptOutLink = styled(Link)`
export const OptOutLink = styled.span`
  color: #327ab7;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-decoration-line: underline;
  white-space: pre-line;
  margin-top: 48px;
  cursor: pointer;
`;

export const CartCheckoutButton = styled.button`
  display: inline-block;
  padding: 10px 16px;
  margin-top: 24px;
  border: none;
  background-color: #479de6;
  font-weight: 900;
  color: #fff;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.1s ease;
  width: 100%;
  font-size: 16px;
  &:hover {
    color: #fff;
    background-color: #357ab8;
  }
  &:focus {
    outline: none;
  }
`;
