import React from 'react';
import { Modal } from 'react-responsive-modal';
import { useDispatch } from 'react-redux';
import { HorizontalCenterWrapper, Text } from '../../v3Styles';
import {
  CartCheckoutButton,
  CheckMarkBullet,
  OptOutLink,
  PicsPlusAnnualSubBox,
  PicsPlusAnnualSubBoxDate,
  PicsPlusAnnualSubBoxHeader,
  PicsPlusAnnualSubBoxText,
  PicsPlusContainer,
  PicsPlusHeader,
  PicsPlusHeader2,
  PicsPlusListItem,
  PicsPlusListItemHeader,
  PicsPlusText,
} from './picsPlusModalStyles';
import { setAcceptedPicsPlus } from '../../../../actions/v3Order';
import BackLink from '../../shared/BackLink';

const PicsPlusModal = ({ open, onClose, onSubmit }) => {
  const dispatch = useDispatch();
  const handleSubmit = () => {
    onSubmit();
    dispatch(setAcceptedPicsPlus(true));
  };
  const handleClose = () => {
    onClose();
    dispatch(setAcceptedPicsPlus(false));
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      classNames={{
        root: 'modal-root',
        overlay: 'overlay',
        modal: 'pics-plus-modal',
      }}
    >
      <PicsPlusContainer>
        <BackLink onClick={onClose} style={{ top: '5px', left: '39px' }} />
        <PicsPlusHeader>Pics+</PicsPlusHeader>
        <PicsPlusHeader2>Save more. Get More.</PicsPlusHeader2>
        <Text style={{ fontWeight: 600 }}>
          Pics+ is our thank you to long-term customers:
        </Text>
        <ul>
          <PicsPlusListItem>
            <CheckMarkBullet />
            <div>
              <PicsPlusListItemHeader>
                Free image hosting for life.
              </PicsPlusListItemHeader>
              <PicsPlusText>
                Secure your cherished moments for your family forever. While
                other companies may delete your photos after a year, we'll keep
                them stored safely for you forever.
              </PicsPlusText>
            </div>
          </PicsPlusListItem>
          <PicsPlusListItem>
            <CheckMarkBullet />
            <div>
              <PicsPlusListItemHeader>
                Never pay services fees.
              </PicsPlusListItemHeader>
              <PicsPlusText>
                As a Pics+ member, we reward your commitment (to us and your
                kids!) with zero service fees and reduced shipping fees on every
                order!
              </PicsPlusText>
            </div>
          </PicsPlusListItem>
          <PicsPlusListItem>
            <CheckMarkBullet />
            <div>
              <PicsPlusListItemHeader>
                One membership to cover the entire household.
              </PicsPlusListItemHeader>
              <PicsPlusText>
                A single Pics+ membership covers all your kids and photos!
              </PicsPlusText>
            </div>
          </PicsPlusListItem>
          <PicsPlusListItem>
            <CheckMarkBullet />
            <div>
              <PicsPlusListItemHeader>
                Special deals and discounts, only for you.
              </PicsPlusListItemHeader>
              <PicsPlusText>
                Look out for featured products and special discounts exclusive
                to Pics+ members in the future.
              </PicsPlusText>
            </div>
          </PicsPlusListItem>
          <PicsPlusListItem>
            <CheckMarkBullet />
            <div>
              <PicsPlusListItemHeader>
                Community support.
              </PicsPlusListItemHeader>
              <PicsPlusText>
                The Pics+ program also enables scholarship and charity
                contributions for those that need it most to be able to
                participate in the school portrait experience.
              </PicsPlusText>
            </div>
          </PicsPlusListItem>
        </ul>
        <PicsPlusAnnualSubBox>
          <PicsPlusAnnualSubBoxHeader>
            Billed Annually
          </PicsPlusAnnualSubBoxHeader>
          <PicsPlusAnnualSubBoxDate>on August 1st</PicsPlusAnnualSubBoxDate>
          <PicsPlusHeader>$2.99/year</PicsPlusHeader>
          <PicsPlusAnnualSubBoxText>
            Estimated $30+ in savings over 3 years
          </PicsPlusAnnualSubBoxText>
        </PicsPlusAnnualSubBox>
        <CartCheckoutButton onClick={handleSubmit} id="proceed-to-checkout">
          Proceed to Checkout
        </CartCheckoutButton>
        <HorizontalCenterWrapper>
          <OptOutLink onClick={handleClose}>
            {"No Thanks \n I'll Opt Out of Pics+"}
          </OptOutLink>
        </HorizontalCenterWrapper>
      </PicsPlusContainer>
    </Modal>
  );
};

export default PicsPlusModal;
