import { parsePhoneNumberFromString } from 'libphonenumber-js';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import {
  addToCart,
  updateSMSNumberConfirmed,
} from '../../../../actions/v3Order';
import formatUSD from '../../../../utils/formatUSD';
import SMSNumberConfirmation from '../regular/subcomponents/SMSNumberConfirmation';
import MultiConfigProductItem from './MultiConfigProductItem';
import {
  MultiConfigButton,
  MultiConfigGrid,
  MultiConfigGridItem,
  MultiConfigHeader,
  MultiConfigSubHeader,
  ProductItemButton,
  ProductOptionsWrapper,
} from './multiConfigStyles';
import { useForm } from 'react-hook-form';
import RequiredProductForm from '../required-product-form/RequiredProductForm';

const STEPS = {
  PRODUCT: 0,
  POSE: 1,
  BACKGROUND: 2,
};

const MultiConfig = ({ product }) => {
  const dispatch = useDispatch();
  const { availablePoses, studentGallery, selectedStudent } = useSelector(
    (state) => state.additionalData,
  );
  const { backgroundOptions } = useSelector((state) => state.shoot);
  const smsNumberConfirmed = useSelector(
    (state) => state.v3Order.smsNumberConfirmed,
  );
  const { phone, smsPhone } = useSelector((state) => state.parent);
  const currentUserId = useSelector((state) => state.auth.currentUserId);
  const [step, setStep] = useState(STEPS.PRODUCT);
  const [products, setProducts] = useState({}); // [id]: { product, pose, background }
  const [selectedProductId, setSelectedProductId] = useState(null);
  const isPrepay = useSelector((state) => state.shoot.isPrepay);
  const [backgroundsCategoryIndex, setBackgroundsCategoryIndex] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const sortedProducts = Object.values(products).sort(
    (a, b) => a.product.order - b.product.order,
  );
  const productRequiresSMS = product?.child_products?.some?.(
    (prod) => prod.sms_required,
  );
  const requiredBackgroundsCount = product?.child_products?.reduce(
    (acc, prod) => {
      return acc + prod.backgrounds_count;
    },
    0,
  );
  const requiredPosesCount = product?.child_products?.reduce((acc, prod) => {
    return acc + prod.poses_count;
  }, 0);
  const selectedBackgrounds = Object.values(products).reduce((acc, prod) => {
    if (prod.background) return [...acc, prod.background];
    return acc;
  }, []);
  const selectedPoses = Object.values(products).reduce((acc, prod) => {
    if (prod.pose) return [...acc, prod.pose];
    return acc;
  }, []);
  const [selectedProductOptionKey, setSelectedProductOptionKey] =
    useState(null);
  const { control, handleSubmit, register } = useForm();

  useEffect(() => {
    if (step === STEPS.BACKGROUND && backgroundOptions.length === 0) {
      setStep(STEPS.PRODUCT);
    }
  }, [step]);

  const canBeSubmitted = () => {
    if (product.product_options.length > 0 && !selectedProductOptionKey) {
      return false;
    }
    if (
      (selectedBackgrounds.length === requiredBackgroundsCount ||
        backgroundOptions.length === 0) &&
      selectedPoses.length === requiredPosesCount
    ) {
      return true;
    }
  };

  const handleConfirmSMS = async () => {
    const phoneNumberObject = parsePhoneNumberFromString(
      smsPhone || phone,
      'US',
    );

    if (!phoneNumberObject || !phoneNumberObject.isValid()) {
      Swal.fire('Invalid Phone Number', '', 'error');
      return;
    }
    const result = await Swal.fire({
      title: 'Please Confirm',
      text: `Please confirm ${phoneNumberObject.formatNational()} is the mobile number where we should send your SMS-based products`,
      confirmButtonText: 'OK',
      showCancelButton: true,
      reverseButtons: true,
      focusConfirm: true,
      cancelButtonText: 'Edit',
    });

    if (result.value === true) {
      dispatch(updateSMSNumberConfirmed(true));
      Swal.fire(
        'Confirmed',
        'You may now add the product to your cart',
        'success',
      );
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      setEditMode(true);
    }
  };

  const handleProductClick = (product) => () => {
    setSelectedProductId(product.id);
    if (isPrepay) {
      setStep(STEPS.BACKGROUND);
    } else {
      setStep(STEPS.POSE);
    }
  };

  useEffect(() => {
    if (product.child_products) {
      const newProducts = product.child_products.reduce((acc, prod) => {
        const { id } = prod;
        return {
          ...acc,
          [id]: {
            product: prod,
            background: null,
            pose: null,
          },
        };
      }, {});
      setProducts(newProducts);
    }
  }, [product]);

  const addProductToCart = () => {
    handleSubmit((formState) => {
      if (!smsNumberConfirmed && productRequiresSMS) {
        handleConfirmSMS();
      } else {
        const backgroundIds = [];
        const backgroundSelections = [];
        const poseIds = [];
        const poses = [];
        sortedProducts.forEach((prod) => {
          if (prod.background?.id) {
            backgroundIds.push(prod.background.id);
            backgroundSelections.push(prod.background);
          }
          poseIds.push(prod.pose.id);
          poses.push(prod.pose);
        });

        dispatch(
          addToCart({
            studentId: selectedStudent.id,
            fullproductData: product,
            backgroundIds,
            backgroundSelections,
            poseIds,
            poses,
            quantity: 1,
            requiredFields: formState,
            productOptionKey: selectedProductOptionKey,
          }),
        );
      }
    })();
  };

  const addBackgroundSelection = (background) => () => {
    const newProducts = { ...products };
    if (newProducts[selectedProductId]) {
      newProducts[selectedProductId].background = background;
    }
    setStep(STEPS.PRODUCT);
  };

  const addPoseSelection = (pose) => {
    const newProducts = { ...products };
    if (newProducts[selectedProductId]) {
      newProducts[selectedProductId].pose = pose;
    }
    setStep(STEPS.BACKGROUND);
  };

  const handleProductOptionChange = (e) => {
    setSelectedProductOptionKey(e.target.value);
  };

  const renderBackgroundCategoryTabs = () => {
    if (backgroundOptions.length > 1) {
      return backgroundOptions.map((backgroundCategory, index) => {
        let activeClass = '';

        if (index === backgroundsCategoryIndex) {
          activeClass = 'group-active';
        }
        return (
          <li
            key={backgroundCategory.id}
            className={`bnl-product-config__bg-selector__tab ${activeClass}`}
            onClick={() => setBackgroundsCategoryIndex(index)}
          >
            {backgroundCategory.name}
          </li>
        );
      });
    }
  };

  const renderBackgroundOptions = () => {
    return backgroundOptions[backgroundsCategoryIndex]?.background_images?.map(
      (background) => {
        const backgroundOptionStyle = {
          backgroundImage: `url(${background.image_thumbnail})`,
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        };

        const lastPose = products[selectedProductId].pose;

        return (
          <MultiConfigGridItem
            key={background.id}
            style={backgroundOptionStyle}
            onClick={addBackgroundSelection(background)}
          >
            <img
              src={lastPose.url}
              onContextMenu={(e) => e.preventDefault()}
              alt="Overlay Pose"
            />
            <ProductItemButton>Choose Background</ProductItemButton>
          </MultiConfigGridItem>
        );
      },
    );
  };

  const renderPoses = () => {
    let poseSource =
      studentGallery && studentGallery?.length > 0
        ? studentGallery
        : availablePoses;

    // if (allAvailablePosesForOrder?.length > 0) {
    //   poseSource = allAvailablePosesForOrder;
    // }

    const poses = poseSource?.map((pose) => {
      // TODO: handle group image type
      return (
        <MultiConfigGridItem
          key={pose.id}
          onClick={() => {
            addPoseSelection(pose);
          }}
        >
          <img
            src={pose.url}
            onContextMenu={(event) => event.preventDefault()}
          />
          <ProductItemButton>Select & Continue</ProductItemButton>
        </MultiConfigGridItem>
      );
    });

    return poses;
  };

  const renderDirections = () => {
    const productNamePrice = `${product.name} (${formatUSD(
      product.price,
      true,
    )})`;
    const childProductName = products?.[selectedProductId]?.product?.name;
    switch (step) {
      case STEPS.POSE:
        if (childProductName) {
          return (
            <>
              <MultiConfigSubHeader>{productNamePrice}</MultiConfigSubHeader>
              <MultiConfigSubHeader>{`${childProductName}: please select one of the poses below for this product.`}</MultiConfigSubHeader>
            </>
          );
        }
      case STEPS.BACKGROUND:
        if (childProductName) {
          return (
            <>
              <MultiConfigSubHeader>{productNamePrice}</MultiConfigSubHeader>
              <MultiConfigSubHeader>{`${childProductName}: please select one of the backgrounds below for this product.`}</MultiConfigSubHeader>
            </>
          );
        }
      case STEPS.PRODUCT:
      default:
        return (
          <MultiConfigSubHeader>
            {`${productNamePrice}: Choose from any pose for each of these products:`}
          </MultiConfigSubHeader>
        );
    }
  };

  return (
    <div>
      <MultiConfigHeader>
        Choose your preferred photo for each item
      </MultiConfigHeader>
      {renderDirections()}
      {step === STEPS.BACKGROUND && backgroundOptions?.length > 1 && (
        <ul className="bnl-product-config__bg-selector__tabs-container">
          {renderBackgroundCategoryTabs()}
        </ul>
      )}
      <MultiConfigGrid>
        {step === STEPS.PRODUCT && (
          <>
            {sortedProducts.map((prod) => (
              <MultiConfigProductItem
                key={prod.id}
                item={prod}
                onClick={handleProductClick}
              />
            ))}
          </>
        )}
        {step === STEPS.POSE && renderPoses()}
        {step === STEPS.BACKGROUND && renderBackgroundOptions()}
      </MultiConfigGrid>
      {canBeSubmitted() && productRequiresSMS && (
        <SMSNumberConfirmation
          currentUserId={currentUserId}
          editMode={editMode}
          setEditMode={setEditMode}
          smsNumber={smsPhone}
          phone={phone}
          smsNumberConfirmed={smsNumberConfirmed}
        />
      )}
      {canBeSubmitted() && (
        <MultiConfigButton onClick={addProductToCart}>
          Done, continue
        </MultiConfigButton>
      )}
      {product.product_options.length > 0 && (
        <ProductOptionsWrapper>
          <label htmlFor="product-options">
            Product Option{'  '}
            <select
              name="product-option"
              onChange={handleProductOptionChange}
              value={selectedProductOptionKey ?? ''}
            >
              <option value="">Select...</option>
              {product.product_options.map((option) => (
                <option key={option.id} value={option.key}>
                  {option.name}
                </option>
              ))}
            </select>
          </label>
        </ProductOptionsWrapper>
      )}
      <RequiredProductForm
        product={product}
        register={register}
        control={control}
      />
    </div>
  );
};

export default MultiConfig;
