import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 1em 0;
  height: 100%;
`;

export const Header = styled.h1`
  font-size: 60px;
  font-weight: bold;
  line-height: 72px;
  font-family: 'Inter', sans-serif;
  @media screen and (max-width: 500px) {
    font-size: 40px;
    line-height: 48px;
  }
`;

export const Header2 = styled.h2`
  font-size: 48px;
  font-weight: 700;
  line-height: 48px;
  font-family: 'Inter', sans-serif;
  @media screen and (max-width: 500px) {
    font-size: 30px;
    line-height: 38px;
  }
`;

export const Text = styled.p`
  font-size: 24px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  @media screen and (max-width: 500px) {
    font-size: 14px;
  }
`;

export const AlertText = styled(Text)`
  color: #ff0000;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
`;

export const RedItalicText = styled(AlertText)`
  font-weight: 400;
  font-style: italic;
`;

export const PrimaryButton = styled.button`
  background-color: ${({ disabled }) => (disabled ? '#6c757d' : '#0b76b7')};
  color: white;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  transition: background-color 0.3s;
  width: auto;

  &:hover {
    background-color: ${({ disabled }) =>
      disabled ? '#6c757d' : '#357abd'}; // a darker shade for hover
  }
`;

export const SecondaryButton = styled.button`
  background-color: white;
  color: #0b76b7;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 5px;
  border: 2px solid #0b76b7;
  cursor: pointer;
  transition: background-color 0.3s;
  width: auto;
`;

export const Bold = styled.span`
  font-weight: 700;
`;

export const InterText = styled(Text)`
  font-family: 'Inter', sans-serif;
`;
export const BackButton = styled.div`
  color: #479de6;
  font-size: 16px;
  display: inline-block;

  &:hover {
    color: #479de6;

    cursor: pointer;
    text-decoration: underline;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HorizontalCenterWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
