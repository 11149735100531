import React from 'react';
import { withRouter } from 'react-router-dom';

const BackLink = ({ history, onClick, className, style }) => (
  <div
    className={`bnl-product-group__back-button ${className || ''}`}
    style={style}
    onClick={onClick || history.goBack}
  >
    &larr; Back
  </div>
);

export default withRouter(BackLink);
