import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import usePreventNavigation from '../../../../hooks/usePreventNavigation';
import { useCurrentOrderPromptedInterstitialProductIds } from '../../../../hooks/useCurrentOrderPromptedInterstitialProductIds';
import RegularProductConfig from '../regular/RegularProductConfig';
import cartUtils from '../../../../utils/cart';
import { setProductAsPrompted } from '../../../../actions/v3Order';

const InterstitialConfig = ({ history }) => {
  // prevent user from navigating away from this page
  usePreventNavigation(history, '/v3/interstitial');

  const dispatch = useDispatch();

  const promptedInterstitialProductIds =
    useCurrentOrderPromptedInterstitialProductIds();

  const {
    additionalData: { selectedStudent },
    shoot: { interstitialProducts },
    v3Order: { orders },
  } = useSelector((state) => state);

  const [interstitialProductsToConfig, setInterstitialProductsToConfig] =
    useState([]);

  const currentOrder = orders[selectedStudent.id];
  const cartItems = currentOrder.cartItems || [];

  useEffect(() => {
    const interstitialProductsToConfig = cartUtils.interstitialProductsToPrompt(
      interstitialProducts,
      promptedInterstitialProductIds,
      cartItems,
    );

    setInterstitialProductsToConfig(interstitialProductsToConfig);

    if (
      interstitialProductsToConfig &&
      interstitialProductsToConfig.length === 0
    ) {
      dispatch(push('/v3/offers'));
    }
  }, [dispatch, interstitialProducts, promptedInterstitialProductIds]);

  const interstitialProduct =
    interstitialProductsToConfig.length > 0
      ? interstitialProductsToConfig[0]
      : null;

  // handle error case
  if (!interstitialProduct) {
    return (
      <div>
        <p>There was an error</p>
        <p>Error code: E212 - no interstitial product to display</p>
      </div>
    );
  }

  return (
    <RegularProductConfig
      interstitialProducts={interstitialProducts}
      product={interstitialProduct}
    />
  );
};

InterstitialConfig.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    block: PropTypes.func.isRequired,
  }).isRequired,
};

export default InterstitialConfig;
